module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"De Gouden Koets","short_name":"De Gouden Koets","start_url":"/","background_color":"#EC464D","theme_color":"#EC464D","display":"minimal-ui","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c4e39589d8e14f21f9c399f4161afccc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-5TZLXHX","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"1644953575802733","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"custom":[{"name":["SNV W01 Regular D","Snv Cond D Fixed","SNVW01-ExtraCondensedD","Söhne Breit Test","DM Sans","DM Sans","Eczar","GT Flexa Trial","GT Flexa Trial","Everett","Everett-Light"],"file":"/fonts/fonts.css"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
