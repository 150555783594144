exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-advice-en-tsx": () => import("./../../../src/pages/advice.en.tsx" /* webpackChunkName: "component---src-pages-advice-en-tsx" */),
  "component---src-pages-adviezen-nl-tsx": () => import("./../../../src/pages/adviezen.nl.tsx" /* webpackChunkName: "component---src-pages-adviezen-nl-tsx" */),
  "component---src-pages-boek-nl-tsx": () => import("./../../../src/pages/boek.nl.tsx" /* webpackChunkName: "component---src-pages-boek-nl-tsx" */),
  "component---src-pages-book-en-tsx": () => import("./../../../src/pages/book.en.tsx" /* webpackChunkName: "component---src-pages-book-en-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-nl-tsx": () => import("./../../../src/pages/index.nl.tsx" /* webpackChunkName: "component---src-pages-index-nl-tsx" */),
  "component---src-pages-kennis-nl-tsx": () => import("./../../../src/pages/kennis.nl.tsx" /* webpackChunkName: "component---src-pages-kennis-nl-tsx" */),
  "component---src-pages-knowledge-en-tsx": () => import("./../../../src/pages/knowledge.en.tsx" /* webpackChunkName: "component---src-pages-knowledge-en-tsx" */),
  "component---src-pages-magazine-en-tsx": () => import("./../../../src/pages/magazine.en.tsx" /* webpackChunkName: "component---src-pages-magazine-en-tsx" */),
  "component---src-pages-meningen-nl-tsx": () => import("./../../../src/pages/meningen.nl.tsx" /* webpackChunkName: "component---src-pages-meningen-nl-tsx" */),
  "component---src-pages-opinion-en-tsx": () => import("./../../../src/pages/opinion.en.tsx" /* webpackChunkName: "component---src-pages-opinion-en-tsx" */),
  "component---src-pages-shop-nl-tsx": () => import("./../../../src/pages/shop.nl.tsx" /* webpackChunkName: "component---src-pages-shop-nl-tsx" */),
  "component---src-pages-styleguide-tsx": () => import("./../../../src/pages/styleguide.tsx" /* webpackChunkName: "component---src-pages-styleguide-tsx" */),
  "component---src-pages-tickets-nl-tsx": () => import("./../../../src/pages/tickets.nl.tsx" /* webpackChunkName: "component---src-pages-tickets-nl-tsx" */),
  "component---src-pages-verdieping-nl-tsx": () => import("./../../../src/pages/verdieping.nl.tsx" /* webpackChunkName: "component---src-pages-verdieping-nl-tsx" */),
  "component---src-templates-event-en-tsx": () => import("./../../../src/templates/event-en.tsx" /* webpackChunkName: "component---src-templates-event-en-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-magazine-item-en-tsx": () => import("./../../../src/templates/magazine-item-en.tsx" /* webpackChunkName: "component---src-templates-magazine-item-en-tsx" */),
  "component---src-templates-magazine-item-tsx": () => import("./../../../src/templates/magazine-item.tsx" /* webpackChunkName: "component---src-templates-magazine-item-tsx" */),
  "component---src-templates-mening-en-tsx": () => import("./../../../src/templates/mening-en.tsx" /* webpackChunkName: "component---src-templates-mening-en-tsx" */),
  "component---src-templates-mening-tsx": () => import("./../../../src/templates/mening.tsx" /* webpackChunkName: "component---src-templates-mening-tsx" */),
  "component---src-templates-page-en-tsx": () => import("./../../../src/templates/page-en.tsx" /* webpackChunkName: "component---src-templates-page-en-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

